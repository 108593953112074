<template>
  <Dialog v-model:visible="display" :header="title" @hide="$emit('close')" :modal="true" class="p-fluid">
    <div class="p-fluid">
      <div class="p-field p-grid">
        <label class="p-col-fixed" style="width:100px;justify-content: flex-end"><span style="color:red;margin-right: 4px;">*</span>名称:</label>
        <div class="p-col">
          <InputText v-model="form.name" :class="{'p-invalid':(v$.form.name.$invalid && submitted)}"></InputText>
          <span v-if="v$.form.name.required.$invalid && submitted" style="font-size: 12px;color: red">请输入名称！</span>
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed" style="width:100px;justify-content: flex-end"><span style="color:red;margin-right: 4px;">*</span>编号:</label>
        <div class="p-col">
          <InputText v-model="form.code" :class="{'p-invalid':(v$.form.code.$invalid && submitted)}"></InputText>
          <span v-if="v$.form.code.required.$invalid && submitted" style="font-size: 12px;color: red">请输入编号！</span>
        </div>
      </div>
    </div>
    <template #footer>
      <Button class="p-button-text" @click="cancel">取消</Button>
      <Button @click="submit(v$.$invalid)">保存</Button>
    </template>
  </Dialog>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  setup: () => ({
    v$: useVuelidate(),
  }),
  data() {
    return {
      title: null,
      display: false,
      submitted: false,
      form: {
        name: null,
        code: null,
        id: null,
        pid: null,
        type: null,
      },
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        code: { required },
      },
    };
  },
  methods: {
    init(data) {
      if (data.label == "编辑") {
        this.form.id = data.data.id;
        this.form.name = data.data.name;
        this.form.code = data.data.code;
        this.form.pid = data.data.pid;
        this.form.type = data.data.type;
      } else {
        this.form.pid = data.data.id;
        this.form.type = Number(data.data.type) + 1;
      }

      this.display = true;
      switch (data.data.type) {
        case 0:
          this.title = data.label + "分类";
          break;
        case 1:
          if (data.label == "编辑") {
            this.title = data.label + "分类";
          } else {
            this.title = data.label + "语言";
          }
          break;
        default:
          this.title = data.label + "语言";
      }
    },
    submit(isInvalid) {
      this.submitted = true;
      if (isInvalid) {
        return;
      }
      let that = this;
      this.$http("/i18n/addOrUpdateType", "post", this.form, function () {
        that.display = false;
      });
    },
    cancel() {
      this.display = false;
    },
  },
};
</script>

<style scoped>
.p-fluid {
  width: 450px;
}

/*.p-field {*/
/*  margin: 0 auto 2rem;*/
/*}*/

label {
  width: 200px;
  text-align: right;
  justify-content: flex-end;
}

.p-field > span {
  width: 100%;
  padding-left: 200px;
  line-height: 34px;
  color: red;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
