<template>
  <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" style="padding-top:0px;background: none;border: none;"/>
  <div class=" card">
    <Splitter style="border:none">
      <SplitterPanel :size="20">
        <h5>语言包类型</h5>
        <Tree :value="treeData" selectionMode="single" v-model:selection-keys="selectedKey" :expandedKeys="expandedKeys"
              @contextmenu="contextmenu($event)" @node-select="treeNodeSelect"
              @node-unselect="selectedNode=null">
        </Tree>
        <ContextMenu ref="menu" :model="rightMenu" style="width: 90px">
          <template #item="{item}">
            <p :style="{color:item.color,padding:'0.5rem 1rem',margin:0,cursor:'pointer'}" @click="choiceStyle(item)">
              {{ item.label }}</p>
          </template>
        </ContextMenu>
      </SplitterPanel>
      <SplitterPanel :size="80" style="padding-left: 1rem">
        <div class="p-mb-3">
          <Button v-permission="['1010','languages','dyyxzct']"
                  :disabled="selectedNode&&selectedNode.type==2?null:'disabled'" @click="addLanguageContent()">新增词条
          </Button>
        </div>
        <DataTable :value="data" :row-hover="true" v-model:selection="selectedRow" selection-mode="single"
                   style="overflow:scroll;max-height:34rem">
          <template #empty>
            暂无数据。
          </template>
          <Column field="name" header="词条名称"></Column>
          <Column field="content" header="词条内容" style="overflow-x:scroll"></Column>
          <Column header="操作" :frozen="true" alignFrozen="right">
            <template #body="row">
              <Button v-permission="['1010','languages','dyybj']" class="p-button-text"
                      @click="addLanguageContent(row.data)">编辑
              </Button>
              <Button v-permission="['1010','languages','dyysc']" class="p-button-text" @click="deleteMain(row.data)">
                删除
              </Button>
            </template>
          </Column>
        </DataTable>
        <Paginator :rows="page.size" :totalRecords="page.total" @page="changePage"
                   template=" PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown CurrentPageReport"
                   :rowsPerPageOptions="[10,20,50,100]"
                   currentPageReportTemplate="共{totalRecords} 条记录">
          <template #right>
            <label>跳至&nbsp;&nbsp;</label>
            <InputNumber v-model="page.current" @keydown.enter="getList" v-tooltip.focus="'按下回车键后跳转'"
                         inputStyle="width: 40px;"/>
            <label>&nbsp;&nbsp;页</label>
          </template>
        </Paginator>
        <dict-item-table ref="item"></dict-item-table>
      </SplitterPanel>
    </Splitter>
    <AddLanguageContent v-if="addLanguageContentDisplay" ref="addlanguagecontent" @close="closeDialog"
                        @id='id'></AddLanguageContent>
    <AddTreeNode v-if="addTreeNodeDisplay" ref="addtreenode" @close="closeDialog"></AddTreeNode>
  </div>
</template>

<script>
import AddLanguageContent from "@/views/modules/language/addLanguageContent";
import AddTreeNode from "@/views/modules/language/addTreeNode";

export default {
  name: "dataDictionary",
  components: { AddLanguageContent, AddTreeNode },
  data() {
    return {
      breadcrumb: {
        home: { label: "系统管理", to: "#" },
        items: [{ label: "多语言管理", to: "#" }],
      },
      treeData: null,
      rightMenu: null,
      addTreeNodeDisplay: false,
      addLanguageContentDisplay: false,
      selectedNode: null,
      selectedKey: {},
      data: null,
      page: {
        size: 10,
        total: 0,
        current: 1,
      },
      expandedKeys: {},
      selectedRow: null,

    };
  },
  mounted() {

    this.getTreeData();
    this.getList()
  },
  methods: {
    id(val) {
      this.selectedNode.id = val
    },
    getTreeData() {
      this.$http("/i18n/getTreeData", "get", null, (res) => {
        this.treeData = res.data;
        this.expandedKeys[res.data[0].key] = true;
        this.expandedKeys[res.data[0].children[0].key] = true;
        this.selectedNode = res.data[0].children[0].children[0];
      });
    },
    getList() {
      this.$http(
        "/i18n/getTreeItemById",
        "get",
        {
          id: this.selectedNode.id,
          size: this.page.size,
          current: this.page.current,
        },
        (res) => {
          if (res.data == null) {
            this.data = null
            this.page.total = 0
          }
          this.data = res.data.records;
          this.page.total = res.data.total;
        }
      );
    },
    choiceStyle(type) {
      if (type.label != "删除") {
        this.addTreeNodeDisplay = true;
        this.$nextTick(() => {
          this.$refs.addtreenode.init(type);
        });
      } else {
        this.deleteTreeNode(type);
      }
    },
    //点击树节点
    treeNodeSelect(data) {
      this.selectedNode = data;
      switch (data.type) {
        case 0:
          this.rightMenu = [
            {
              label: "编辑",
              key: 0,
              data: data,
            },
            {
              label: "新增",
              key: 1,
              data: data,
            },
          ];
          break;
        case 2:
          this.rightMenu = [
            {
              label: "编辑",
              key: 0,
              data: data,
            },
            {
              label: "删除",
              color: "red",
              key: 1,
              data: data,
            },
          ];
          break;
        default:
          this.rightMenu = [
            {
              label: "编辑",
              key: 0,
              data: data,
            },
            {
              label: "新增",
              key: 1,
              data: data,
            },
            {
              label: "删除",
              color: "red",
              key: 2,
              data: data,
            },
          ];
      }
      this.getList();
    },
    contextmenu(event) {
      if (this.selectedNode) {
        this.$refs.menu.show(event);
      }
    },
    deleteTreeNode(data) {
      if (data.data.children && data.data.children.length > 0) {
        this.$toast.add({
          severity: "warn",
          summary: "注意",
          detail: "此节点下存在子节点，不能删除！",
          life: 3000,
        });
      } else {
        this.$confirm.require({
          header: "删除",
          message: '您确定要删除"' + data.data.name + '"吗',
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "确认",
          rejectLabel: "取消",
          accept: () => {
            this.$http(
              "/i18n/deleteTypeById",
              "get",
              { id: data.data.id },
              () => {
                this.$toast.add({
                  severity: "success",
                  summary: "成功",
                  detail: "删除成功",
                  life: 3000,
                });
                this.$confirm.close();
                this.getTreeData();
                this.getList()
              },
              () => {
                this.$toast.add({
                  severity: "warn",
                  summary: "失败",
                  detail: "此节点下存在数据，不能删除！",
                  life: 3000,
                });
                this.$confirm.close();
              }
            );
          },
          reject: () => {
            this.$confirm.close();
          },
        });
      }
    },
    closeDialog() {
      this.addLanguageContentDisplay = false;
      this.addTreeNodeDisplay = false;
      this.getTreeData();
      this.getList()
    },
    changePage(page) {
      this.page.size = page.rows;
      this.page.current = page.page + 1;
      this.getList();
    },
    //新增/编辑词条
    addLanguageContent(data) {
      this.addLanguageContentDisplay = true;
      this.$nextTick(() => {
        this.$refs.addlanguagecontent.init(this.selectedNode.id, data);
      });
    },
    //删除词条
    deleteMain(data) {
      this.$confirm.require({
        header: "删除",
        message: '您确定要删除"' + data.name + '"吗',
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "确认",
        rejectLabel: "取消",
        accept: () => {
          this.$http("/i18n/deleteById/", "get", { id: data.id }, () => {
            this.$toast.add({
              severity: "success",
              summary: "成功",
              detail: "删除成功",
              life: 3000,
            });
            this.$confirm.close();
            this.getList();
          });
        },
        reject: () => {
          this.$confirm.close();
        },
      });
    },
  },
};
</script>

<style scoped>
::v-deep(.p-tree) {
  border: none;
  padding: 0;
  cursor: pointer;
}

::v-deep(.p-datatable .p-datatable-tbody > tr > td) {
  white-space: nowrap;
  justify-content: center;
  text-align: center;
}

::v-deep(.p-datatable .p-column-header-content) {
  white-space: nowrap;
  justify-content: center;
  text-align: center;
}
</style>
